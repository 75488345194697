<script setup>
import { Link, router } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import MultiselectInput from '@/Shared/MultiselectInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { useDate } from '@/Composables/useDate'
import { useConfirm } from '@/Composables/useConfirm'

import { ref, watch } from 'vue'
import SelectInput from '@/Shared/SelectInput.vue'
import Icon from '@/Shared/Icon.vue'

const props = defineProps({
  group: {
    type: Object,
    required: true
  },
  games: {
    type: Array,
    required: true
  },
  players: {
    type: Array,
    required: true
  },
  teams: {
    type: Array,
    required: true
  },
  operations: {
    type: Array,
    required: true
  }
})

let form = useForm({
  name: props.group.name,
  players: props.group.participants
    .filter((participant) => participant.participant_type === 'player')
    .map((participant) => participant.participant),
  teams: props.group.participants
    .filter((participant) => participant.participant_type === 'ranking-team')
    .map((participant) => participant.participant)
})

const participantsForms = ref(
  props.group.participants.reduce((acc, participant) => {
    acc[participant.id] = {
      operation: participant.operation
    }
    return acc
  }, {})
)

watch(
  participantsForms,
  (newState) => {
    const participants = []

    for (const participantId in newState) {
      participants.push({
        id: participantId,
        operation: newState[participantId].operation
      })
    }

    router.put(
      `/rankings/phases/groups/${props.group.id}/participants/operations`,
      { participants },
      { preserveScroll: true }
    )
  },
  { deep: true }
)

function update() {
  form.put(`/rankings/phases/groups/${props.group.id}/update`, {
    preserveScroll: true,
    preserveState: false
  })
}

async function remove() {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el grupo de ranking?')) {
    router.delete(`/rankings/phases/groups/${props.group.id}/delete`)
  }
}

async function removeGame(gameId) {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el partido?')) {
    router.delete(`/rankings/phases/groups/games/${gameId}/delete`)
  }
}

async function notifyAvailability(participant) {
  if (
    await useConfirm(
      `¿Estas seguro de que quieres notificar al equipo ${participant.name} que cubran la disponibilidad del ranking?`
    )
  ) {
    router.post(`/rankings/phases/groups/${props.group.id}/participants/${participant.id}/notify`)
  }
}
</script>

<template>
  <div>
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/phases/${group.phase.id}/edit`"
      >
        {{ ` ${group.phase.ranking.name} ` }}
      </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/phases/${group.phase.id}/edit`"
      >
        {{ ` ${group.phase.name} ` }}
      </Link>
      <span>/</span>
      {{ form.name }}
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
          />
          <MultiselectInput
            v-if="group.phase.ranking.modality === 'doubles'"
            v-model="form.teams"
            :options="teams"
            :error="form.errors.teams"
            class="pb-8 pr-6 w-full"
            label="Elige equipos"
            input-label="name"
            track-by="name"
          />
          <MultiselectInput
            v-else
            v-model="form.players"
            :options="players"
            :error="form.errors.players"
            class="pb-8 pr-6 w-full"
            label="Elige jugadores"
            input-label="fullname"
            track-by="fullname"
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="remove">
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>

    <h2 class="mt-12 text-xl font-bold">Participantes</h2>
    <div class="bg-white rounded-md shadow overflow-x-auto mt-6">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Nombre</th>
            <th class="pb-4 pt-6 px-6">Operaciones al terminar la fase</th>
            <th class="pb-4 pt-6 px-6">Puntos</th>
            <th class="pb-4 pt-6 px-6">Victorias</th>
            <th class="pb-4 pt-6 px-6">Derrotas</th>
            <th class="pb-4 pt-6 px-6"></th>
          </tr>
        </thead>
        <tbody ref="sortableList">
          <tr
            v-for="participant in group.participants"
            :key="participant.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <span class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ participant.participant.fullname || participant.participant.name }}
              </span>
            </td>
            <td class="border-t">
              <div>
                <SelectInput
                  v-model="participantsForms[participant.id].operation"
                  class="py-2 px-6 w-full"
                >
                  <option
                    v-for="operation in operations"
                    :key="operation.value"
                    :value="operation.value"
                  >
                    {{ operation.name }}
                  </option>
                </SelectInput>
              </div>
            </td>
            <td class="border-t">
              <span class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ participant.points }}
              </span>
            </td>
            <td class="border-t">
              <span class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ participant.wins }}
              </span>
            </td>
            <td class="border-t">
              <span class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ participant.losses }}
              </span>
            </td>
            <td class="border-t">
              <div class="flex items-center justify-end space-x-4 last-of-type:pr-4">
                <a :href="participant.url" target="_blank">
                  <Icon name="Eye" />
                </a>
                <button @click="notifyAvailability(participant)">
                  <Icon name="BellAlert" />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="group.participants.length === 0">
            <td class="px-6 py-4 border-t" colspan="6">No hay participantes todavía.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2 class="mt-12 text-xl font-bold">Partidos</h2>
    <div class="flex items-center justify-end my-6 space-x-4">
      <Link class="btn-indigo" :href="`/rankings/phases/groups/${group.id}/games/calendar`">
        <span>Calendario</span>
      </Link>
    </div>

    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Partido</th>
            <th class="pb-4 pt-6 px-6">Fecha</th>
            <th class="pb-4 pt-6 px-6">Ganador</th>
            <th class="pb-4 pt-6 px-6">Resultado</th>
            <th class="pb-4 pt-6 px-6"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="game in games"
            :key="game.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <span class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ game.participant_one.name }} vs {{ game.participant_two.name }}
              </span>
            </td>
            <td class="border-t">
              <span class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ useDate(game.date_start).format('DD-MM-YYYY ') }}
                {{ useDate(game.date_start).format('HH:mm') }} -
                {{ useDate(game.date_end).format('HH:mm') }}
              </span>
            </td>
            <td class="border-t">
              <span class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ game.participant_winner ? game.participant_winner.name : '-' }}
              </span>
            </td>
            <td class="border-t">
              <div class="flex items-center px-6 py-4 focus:text-indigo-500">
                {{ game.result || '-' }}
              </div>
            </td>
            <td class="border-t">
              <div class="flex items-center justify-end space-x-4 last-of-type:pr-4">
                <a :href="game.url" target="_blank" class="inline-flex items-center">
                  <Icon name="Eye" />
                </a>
                <button type="button" class="inline-flex items-center" @click="removeGame(game.id)">
                  <Icon name="Trash" class="w-6 h-5 text-black" />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="games.length === 0">
            <td class="px-6 py-4 border-t" colspan="5">No hay partidos todavía.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
