<script setup>
import { Link, router } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { useConfirm } from '@/Composables/useConfirm'
import { useDate } from '@/Composables/useDate'
import { ref, watch } from 'vue'

const props = defineProps({
  phase: {
    type: Object,
    required: true
  },
  statuses: {
    type: Array,
    required: true
  }
})

function phaseStatus(status) {
  return props.statuses.find((s) => s.value === status)
}

let form = useForm({
  name: props.phase.name,
  date_start: useDate(props.phase.date_start).format('YYYY-MM-DD'),
  date_end: useDate(props.phase.date_end).format('YYYY-MM-DD')
})

const status = ref(props.phase.status)

watch(
  () => status.value,
  async (newStatus, oldStatus) => {
    if (
      await useConfirm(
        `¿Estas seguro de que quieres cambiar de estado ${phaseStatus(oldStatus).name} a ${
          phaseStatus(newStatus).name
        }?`
      )
    ) {
      router.put(`/rankings/phases/${props.phase.id}/status/update`, { status: newStatus })
    }
  }
)

function update() {
  form.put(`/rankings/phases/${props.phase.id}/update`)
}

async function remove() {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el grupo de ranking?')) {
    router.delete(`/rankings/phases/${props.phase.id}/delete`)
  }
}
</script>

<template>
  <div>
    <div class="max-w-3xl flex justify-between items-center mb-8">
      <div class="w-full">
        <h1 class="text-xl font-bold">
          <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
          <span>/</span>
          <Link
            class="text-indigo-400 hover:text-indigo-600"
            :href="`/rankings/${phase.ranking_id}/edit`"
          >
            {{ ` ${phase.ranking.name} ` }}
          </Link>
          <span>/</span>
          {{ phase.name }}
        </h1>
      </div>
      <div class="flex justify-end">
        <div class="flex items-center focus:text-indigo-500">
          <select
            v-model="status"
            class="px-4 py-2 rounded form-select"
            :style="{ backgroundColor: phaseStatus(status).color }"
          >
            <option v-for="s in statuses" :key="s.value" :value="s.value">
              {{ s.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full"
            label="Nombre"
            type="text"
          />
          <TextInput
            v-model="form.date_start"
            :error="form.errors.date_start"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha inicio"
            type="date"
          />
          <TextInput
            v-model="form.date_end"
            :error="form.errors.date_end"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha fin"
            type="date"
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="remove">
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>

    <h2 class="mt-12 text-xl font-bold">Grupos</h2>
    <div class="flex items-center justify-end mb-6 space-x-4">
      <Link class="btn-indigo" :href="`/rankings/phases/${phase.id}/groups/create`">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Grupo</span>
      </Link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto mt-6">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Nombre</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="group in phase.groups"
            :key="group.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="`/rankings/phases/groups/${group.id}/edit`"
              >
                {{ group.name }}
              </Link>
            </td>
          </tr>
          <tr v-if="phase.groups.length === 0">
            <td class="px-6 py-4 border-t" colspan="5">No hay grupos todavía.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
