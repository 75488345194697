<script setup>
import { Link } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = defineProps({
  modalities: {
    type: Array,
    required: true
  }
})

let form = useForm({
  name: '',
  modality: ''
})

const availableModalities = computed(() => {
  return props.modalities //.filter((modality) => modality.value === 'doubles')
})

function store() {
  form.post('/rankings/store')
}
</script>

<template>
  <div>
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span> Crear
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
          />
          <SelectInput
            v-model="form.modality"
            placeholder="Elige una modalidad"
            :error="form.errors.modality"
            class="pb-8 pr-6 w-full"
            label="Modalidad"
          >
            <option
              v-for="modality in availableModalities"
              :key="modality.value"
              :value="modality.value"
            >
              {{ modality.name }}
            </option>
          </SelectInput>
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
