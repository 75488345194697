<script setup>
import { Link } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import ToggleInput from '@/Shared/ToggleInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import Pagination from '@/Shared/Pagination.vue'
import { useForm, router } from '@inertiajs/vue3'
import { watch } from 'vue'
import { useDate } from '@/Composables/useDate'
import { useConfirm } from '@/Composables/useConfirm'

const props = defineProps({
  coaches: {
    type: Array,
    default: () => []
  },
  filters: {
    type: Object,
    default: null
  },
  lesson: {
    type: Object,
    required: true
  },
  levels: {
    type: Object,
    required: true
  },
  students: {
    type: Array,
    default: () => []
  },
  weekdays: {
    type: Array,
    default: () => []
  },
  records: {
    type: Object,
    required: true
  }
})

let form = useForm({
  coach_id: props.coaches[0].id,
  level_id: props.filters.level_id || props.lesson.level_id,
  time_start: props.lesson.time_start,
  time_end: props.lesson.time_end,
  weekdays: props.lesson.weekdays,
  students: props.students
    .filter((s) => props.lesson.students.map((st) => st.id).includes(s.id))
    .map((s) => s.id),
  enabled: props.lesson.enabled,
  date_start: useDate(props.lesson.date_start).format('YYYY-MM-DD'),
  date_end: useDate(props.lesson.date_end).format('YYYY-MM-DD'),
  max_students: props.lesson.max_students
})

watch(
  () => form.level_id,
  (level_id) => {
    form.students = []

    router.get(
      `/lessons/${props.lesson.id}/edit`,
      { level_id },
      {
        preserveState: true,
        preserveScroll: true
      }
    )
  }
)

watch(
  () => form.enabled,
  () => {
    router.put(`/lessons/${props.lesson.id}/enable`, {
      preserveState: true,
      preserveScroll: true
    })
  }
)

function update() {
  form.put(`/lessons/${props.lesson.id}/update`)
}

async function deleteLesson() {
  if (await useConfirm('¿Estás seguro de que quieres eliminar la clase?')) {
    router.delete(`/lessons/${props.lesson.id}/delete`)
  }
}
</script>

<template>
  <div>
    <div class="max-w-3xl flex justify-between items-center mb-8">
      <div class="w-full">
        <h1 class="text-xl font-bold">
          <Link class="text-indigo-400 hover:text-indigo-600" href="/lessons"> Clases </Link>
          <span>/</span>
          {{ levels.find((l) => l.id === lesson.level_id).name }} {{ lesson.time_start }} -
          {{ lesson.time_end }}
        </h1>
      </div>
      <ToggleInput v-model="form.enabled" class="w-full flex justify-end" label="Activada" />
    </div>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <SelectInput
            v-model="form.coach_id"
            :error="form.errors.coach_id"
            class="pb-8 pr-6 w-full"
            label="Entrenador"
          >
            <option v-for="coach in coaches" :key="coach.id" :value="coach.id">
              {{ coach.player.fullname }}
            </option>
          </SelectInput>
          <TextInput
            v-model="form.time_start"
            :error="form.errors.time_start"
            type="time"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hora inicio"
          />
          <TextInput
            v-model="form.time_end"
            :error="form.errors.time_end"
            type="time"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Hora fin"
          />
          <SelectInput
            v-model="form.weekdays"
            :error="form.errors.weekdays"
            class="pb-8 pr-6 w-full"
            label="Días"
            multiple
          >
            <option v-for="weekday in weekdays" :key="weekday.value" :value="weekday.value">
              {{ weekday.name }}
            </option>
          </SelectInput>
          <SelectInput
            v-model="form.level_id"
            :error="form.errors.level_id"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nivel"
          >
            <option v-for="level in levels" :key="level.id" :value="level.id">
              {{ level.name }}
            </option>
          </SelectInput>
          <TextInput
            v-model="form.max_students"
            :error="form.errors.max_students"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Número máximo de alumnos"
            type="number"
          />
          <SelectInput
            v-model="form.students"
            :error="form.errors.students"
            class="pb-8 pr-6 w-full"
            label="Alumnos"
            multiple
          >
            <option v-for="student in students" :key="student.id" :value="student.id">
              {{ student.player.fullname }}
            </option>
          </SelectInput>
          <TextInput
            v-model="form.date_start"
            :error="form.errors.date_start"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha inicio"
            type="date"
          />
          <TextInput
            v-model="form.date_end"
            :error="form.errors.date_end"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Fecha fin"
            type="date"
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button
            class="text-red-600 hover:underline"
            tabindex="-1"
            type="button"
            @click="deleteLesson"
          >
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>

    <h2 class="mt-12 text-xl font-bold">Próximas Clases</h2>

    <div class="bg-white rounded-md shadow overflow-x-auto mt-6">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Día</th>
            <th class="pb-4 pt-6 px-6">Hora</th>
            <th class="pb-4 pt-6 px-6">Alumnos</th>
            <th class="pb-4 pt-6 px-6">Entrenador</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in records.data"
            :key="record.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/lessons/records/${record.id}/edit`"
                tabindex="-1"
              >
                {{ useDate(record.date_start).format('DD-MM-YYYY') }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/lessons/records/${record.id}/edit`"
                tabindex="-1"
              >
                {{ useDate(record.date_start).format('HH:mm') }} -
                {{ useDate(record.date_end).format('HH:mm') }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/lessons/records/${record.id}/edit`"
                tabindex="-1"
              >
                {{ record.students.length }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4"
                :href="`/lessons/records/${record.id}/edit`"
                tabindex="-1"
              >
                {{ record.lesson.coach.player.fullname }}
              </Link>
            </td>
            <td class="w-px border-t">
              <Link
                class="flex items-center px-4"
                :href="`/lessons/records/${record.id}/edit`"
                tabindex="-1"
              >
                <Icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
              </Link>
            </td>
          </tr>
          <tr v-if="records.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="5">No hay registros todavía.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination class="mt-6" :links="records.links" />
  </div>
</template>
