<script setup>
import { router } from '@inertiajs/vue3'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { computed } from 'vue'
import { useDate } from '@/Composables/useDate'

const props = defineProps({
  record: {
    type: Object,
    default: null
  },
  student: {
    type: Object,
    default: null
  }
})

function confirm() {
  router.post(`/${props.record.uuid}/student/${props.student.uuid}/accept`)
}

function reject() {
  router.get(`/${props.record.uuid}/student/${props.student.uuid}/reject`)
}

const alreadyJoined = computed(() => {
  return props.record.students.map((s) => s.id).includes(props.student.id)
})

const lessonExpired = computed(() => {
  return props.record.students.length >= props.record.lesson.max_students
})
</script>

<template>
  <div class="w-full max-w-md mx-auto">
    <div v-if="alreadyJoined" class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden">
      <div class="px-10 py-12">
        Ya te has unido a la clase del
        {{ useDate(record.date_start).format('D [de] MMMM [de] YYYY [a las] HH:mm') }}.
      </div>
    </div>
    <div
      v-if="!alreadyJoined && lessonExpired"
      class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden"
    >
      <div class="px-10 py-12">Lo sentimos, ya no es posible unirte a esta clase.</div>
    </div>
    <form
      v-if="!lessonExpired && !alreadyJoined"
      class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden"
    >
      <div class="px-10 py-12">
        <p>
          Hola
          {{ student.player.fullname }}, quieres unirte a la clase el día
          {{ useDate(record.date_start).format('D [de] MMMM [de] YYYY [a las] HH:mm') }}?
        </p>
      </div>
      <div class="flex px-10 py-4 bg-gray-100 border-t border-gray-100 space-x-6">
        <LoadingButton
          type="button"
          class="inline-flex w-full justify-center btn-indigo"
          @click="confirm"
        >
          Confirmar
        </LoadingButton>
        <LoadingButton
          type="button"
          class="inline-flex w-full justify-center btn-white"
          @click="reject"
        >
          Rechazar
        </LoadingButton>
      </div>
    </form>
    <div class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden">
      <div class="px-10 py-12">
        <p class="font-bold">Alumnos que acudirán:</p>
        <p v-if="props.record.students.length > 0" class="mt-4">
          {{ record.students.map((s) => s.player.fullname).join(', ') }}.
        </p>
        <p v-else class="mt-4">No hay alumnos todavía.</p>
      </div>
    </div>
  </div>
</template>
