<script setup>
import { Link } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { ref, watch } from 'vue'
import Icon from '@/Shared/Icon.vue'
import ToggleInput from '@/Shared/ToggleInput.vue'
import FlashMessages from '@/Shared/FlashMessages.vue'

const props = defineProps({
  auth: {
    type: Object,
    default: null
  },
  group: {
    type: Object,
    required: true
  },
  game: {
    type: Object,
    required: true
  }
})

let form = useForm({
  participant_one_set_1: props.game.participant_one_set_1,
  participant_two_set_1: props.game.participant_two_set_1,
  participant_one_set_2: props.game.participant_one_set_2,
  participant_two_set_2: props.game.participant_two_set_2,
  participant_one_set_3: props.game.participant_one_set_3,
  participant_two_set_3: props.game.participant_two_set_3
})

const needsThirdSet = ref(Boolean(form.participant_two_set_3))

watch(
  () => needsThirdSet.value,
  (needsThirdSet) => {
    if (!needsThirdSet) {
      form.participant_one_set_3 = null
      form.participant_two_set_3 = null
    }
  }
)

function updateResult() {
  form.put(`/rankings/phases/groups/games/${props.game.id}/result/update`)
}
</script>

<template>
  <div class="max-w-3xl mx-auto">
    <div class="max-w-full flex justify-between items-center">
      <div class="w-full">
        <h1 class="mb-6 text-xl font-bold">
          <component
            :is="auth.user ? Link : 'span'"
            :class="auth.user ? 'text-indigo-400 hover:text-indigo-600' : ''"
            :href="`/rankings/phases/${group.phase.id}/edit`"
          >
            {{ ` ${group.phase.ranking.name} ` }}
          </component>
          <span>/</span>
          <component
            :is="auth.user ? Link : 'span'"
            :class="auth.user ? 'text-indigo-400 hover:text-indigo-600' : ''"
            :href="`/rankings/phases/${group.phase.id}/edit`"
          >
            {{ ` ${group.phase.name} ` }}
          </component>
          <span>/</span>
          <component
            :is="auth.user ? Link : 'span'"
            :class="auth.user ? 'text-indigo-400 hover:text-indigo-600' : ''"
            :href="`/rankings/phases/groups/${group.id}/edit`"
          >
            {{ ` ${group.name} ` }}
          </component>
        </h1>
      </div>
    </div>

    <FlashMessages class="max-w-3xl w-full" />

    <div class="max-w-full bg-white rounded-md shadow overflow-hidden">
      <h2 class="text-xl font-extrabold text-center mt-6">Resultado del partido</h2>
      <form @submit.prevent="updateResult">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <div class="w-full mb-4 flex items-center gap-2">
            <Icon name="Trophy" class="w-6 h-6" />
            <span class="font-bold"> Primer Set </span>
          </div>
          <TextInput
            v-model="form.participant_one_set_1"
            class="pb-8 pr-6 w-full lg:w-1/2"
            :error="form.errors.participant_one_set_1"
            :label="`${props.game.participant_one.name}`"
            type="number"
          />

          <TextInput
            v-model="form.participant_two_set_1"
            class="pb-8 pr-6 w-full lg:w-1/2"
            :error="form.errors.participant_two_set_1"
            :label="`${props.game.participant_two.name}`"
            type="number"
          />

          <div class="w-full mb-4 flex items-center gap-2">
            <Icon name="Trophy" class="w-6 h-6" />
            <span class="font-bold"> Segundo Set </span>
          </div>

          <TextInput
            v-model="form.participant_one_set_2"
            class="pb-8 pr-6 w-full lg:w-1/2"
            :error="form.errors.participant_one_set_2"
            :label="`${props.game.participant_one.name}`"
            type="number"
          />

          <TextInput
            v-model="form.participant_two_set_2"
            class="pb-8 pr-6 w-full lg:w-1/2"
            :error="form.errors.participant_two_set_2"
            :label="`${props.game.participant_two.name}`"
            type="number"
          />

          <ToggleInput
            v-model="needsThirdSet"
            class="w-full flex pb-8 justify-start"
            label="Desempate"
          />

          <template v-if="needsThirdSet">
            <div class="w-full mb-4 flex items-center gap-2">
              <Icon name="Trophy" class="w-6 h-6" />
              <span class="font-bold"> Desempate </span>
            </div>

            <TextInput
              v-model="form.participant_one_set_3"
              class="pb-8 pr-6 w-full lg:w-1/2"
              :error="form.errors.participant_one_set_3"
              :label="`${props.game.participant_one.name}`"
              type="number"
            />

            <TextInput
              v-model="form.participant_two_set_3"
              class="pb-8 pr-6 w-full lg:w-1/2"
              :error="form.errors.participant_two_set_3"
              :label="`${props.game.participant_two.name}`"
              type="number"
            />
          </template>
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
