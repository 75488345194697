<script setup>
import { Link } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import MultiselectInput from '@/Shared/MultiselectInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
  phase: {
    type: Object,
    default: () => {}
  },
  players: {
    type: Array,
    default: () => []
  },
  teams: {
    type: Array,
    default: () => []
  }
})

let form = useForm({
  name: '',
  teams: [],
  players: []
})

function store() {
  form.post(`/rankings/phases/${props.phase.id}/groups/store`)
}
</script>

<template>
  <div>
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/phases/${phase.id}/edit`"
      >
        {{ ` ${phase.ranking.name} ` }}
      </Link>
      <span>/</span>
      <Link
        class="text-indigo-400 hover:text-indigo-600"
        :href="`/rankings/phases/${phase.id}/edit`"
      >
        {{ ` ${phase.name} ` }}
      </Link>
      <span>/</span>
      Crear Grupo
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
          />
          <MultiselectInput
            v-if="phase.ranking.modality === 'doubles'"
            v-model="form.teams"
            :options="teams"
            :error="form.errors.teams"
            class="pb-8 pr-6 w-full"
            label="Elige equipos"
            input-label="name"
            track-by="name"
          />
          <MultiselectInput
            v-else
            v-model="form.players"
            :options="players"
            :error="form.errors.players"
            class="pb-8 pr-6 w-full"
            label="Elige jugadores"
            input-label="fullname"
            track-by="fullname"
          />
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
