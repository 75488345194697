<script>
import LayoutWeb from '@/Shared/LayoutWeb.vue'

export default {
  layout: LayoutWeb
}
</script>

<script setup>
import Markdown from '@/Shared/Markdown.vue'
</script>

<template>
  <Markdown path="/markdown/privacy-policy.md" />
</template>
