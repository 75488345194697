<script setup>
import { Link, router } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { useConfirm } from '@/Composables/useConfirm'
import { useDate } from '@/Composables/useDate'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import { reactive, ref, watch } from 'vue'

const props = defineProps({
  ranking: {
    type: Object,
    required: true
  },
  filters: {
    type: Object,
    default: () => {}
  },
  teams: {
    type: Object,
    default: () => {}
  },
  modalities: {
    type: Array,
    required: true
  },
  statuses: {
    type: Array,
    required: true
  }
})

const filtersForm = reactive({
  search: props.filters.search
})

const debounce = ref(null)

watch(
  () => filtersForm.search,
  (value) => {
    clearTimeout(debounce.value)

    debounce.value = setTimeout(() => {
      router.get(
        `/rankings/${props.ranking.id}/edit`,
        { search: value },
        { preserveState: true, preserveScroll: true }
      )
    }, 250)
  }
)

let form = useForm({
  name: props.ranking.name,
  modality: props.ranking.modality
})

function update() {
  form.put(`/rankings/${props.ranking.id}/update`)
}

function phaseStatus(status) {
  return props.statuses.find((s) => s.value === status)
}

async function deleteRanking() {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el ranking?')) {
    router.delete(`/rankings/${props.ranking.id}/delete`)
  }
}
</script>

<template>
  <div>
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/rankings"> Rankings </Link>
      <span>/</span>
      {{ form.name }}
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
          />
          <SelectInput
            :model-value="form.modality"
            placeholder="Elige una modalidad"
            class="pb-8 pr-6 w-full"
            label="Modalidad"
            disabled
          >
            <option v-for="modality in modalities" :key="modality.value" :value="modality.value">
              {{ modality.name }}
            </option>
          </SelectInput>
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button
            class="text-red-600 hover:underline"
            tabindex="-1"
            type="button"
            @click="deleteRanking"
          >
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>

    <h2 class="mt-12 text-xl font-bold">Fases</h2>
    <div class="flex items-center justify-end mb-6 space-x-4">
      <Link class="btn-indigo" :href="`/rankings/${ranking.id}/phases/create`">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Fase</span>
      </Link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto mt-6">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">Nombre</th>
            <th class="pb-4 pt-6 px-6">Estado</th>
            <th class="pb-4 pt-6 px-6">Fecha inicio</th>
            <th class="pb-4 pt-6 px-6">Fecha fin</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="phase in ranking.phases"
            :key="phase.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="`/rankings/phases/${phase.id}/edit`"
              >
                <span>
                  {{ phase.name }}
                </span>
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="`/rankings/phases/${phase.id}/edit`"
              >
                <span
                  class="px-4 py-2 rounded"
                  :style="{
                    backgroundColor: phaseStatus(phase.status).color
                  }"
                >
                  {{ phaseStatus(phase.status).name }}
                </span>
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="`/rankings/phases/${phase.id}/edit`"
              >
                {{ useDate(phase.date_start).format('DD-MM-YYYY') }}
              </Link>
            </td>
            <td class="border-t">
              <Link
                class="flex items-center px-6 py-4 focus:text-indigo-500"
                :href="`/rankings/phases/${phase.id}/edit`"
              >
                {{ useDate(phase.date_end).format('DD-MM-YYYY') }}
              </Link>
            </td>
          </tr>
          <tr v-if="ranking.phases.length === 0">
            <td class="px-6 py-4 border-t" colspan="5">No hay fases todavía.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <template v-if="ranking.modality === 'doubles'">
      <h2 class="mt-12 mb-8 text-xl font-bold">Equipos</h2>
      <div class="flex items-center justify-between mb-6 space-x-4">
        <SearchFilter
          v-model="filtersForm.search"
          class="mr-4 w-full max-w-md"
          @reset="filtersForm.search = null"
        />
        <Link class="btn-indigo" :href="`/rankings/${ranking.id}/teams/create`">
          <span>Crear</span>
          <span class="hidden md:inline">&nbsp;Equipo</span>
        </Link>
      </div>
      <div class="bg-white rounded-md shadow overflow-x-auto mt-6">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left font-bold">
              <th class="pb-4 pt-6 px-6">Nombre</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="team in teams.data"
              :key="team.id"
              class="hover:bg-gray-100 focus-within:bg-gray-100"
            >
              <td class="border-t">
                <Link
                  class="flex items-center px-6 py-4 focus:text-indigo-500"
                  :href="`/rankings/teams/${team.id}/edit`"
                >
                  {{ team.name }}
                </Link>
              </td>
            </tr>
            <tr v-if="teams.length === 0">
              <td class="px-6 py-4 border-t" colspan="5">No hay equipos todavía.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination class="mt-6" :links="teams.links" />
    </template>
  </div>
</template>
