<script setup>
import { Link } from '@inertiajs/vue3'
import SelectInput from '@/Shared/SelectInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import ToggleInput from '@/Shared/ToggleInput.vue'

defineProps({
  players: {
    type: Array,
    default: () => []
  }
})

let form = useForm({
  player_id: '',
  is_admin: false,
  observations: ''
})

function store() {
  form.post('/coaches/store')
}
</script>

<template>
  <div>
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/coaches"> Entrenadores </Link>
      <span>/</span> Crear
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <SelectInput
            v-model="form.player_id"
            placeholder="Elige  un jugador"
            :error="form.errors.player_id"
            class="pb-8 pr-6 w-full"
            label="Elige un jugador"
          >
            <option v-for="player in players" :key="player.id" :value="player.id">
              {{ player.fullname }}
            </option>
          </SelectInput>
          <ToggleInput
            v-model="form.is_admin"
            class="w-full flex pb-8 justify-start"
            label="Administrador"
          />
          <TextareaInput
            v-model="form.observations"
            :error="form.errors.observations"
            class="pb-8 pr-6 w-full"
            label="Observaciones"
          />
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Guardar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
