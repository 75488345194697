<script setup>
import { Link, router } from '@inertiajs/vue3'
import TextInput from '@/Shared/TextInput.vue'
import TextareaInput from '@/Shared/TextareaInput.vue'
import LoadingButton from '@/Shared/LoadingButton.vue'
import { useForm } from '@inertiajs/vue3'
import { useConfirm } from '@/Composables/useConfirm'
import ToggleInput from '@/Shared/ToggleInput.vue'

const props = defineProps({
  coach: {
    type: Object,
    required: true
  }
})

let form = useForm({
  is_admin: props.coach.is_admin,
  observations: props.coach.observations
})

function update() {
  form.put(`/coaches/${props.coach.id}/update`)
}

async function remove() {
  if (await useConfirm('¿Estas seguro de que quieres eliminar el profesor?')) {
    router.delete(`/coaches/${props.coach.id}/delete`)
  }
}
</script>

<template>
  <div>
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/coaches"> Entrenadores </Link>
      <span>/</span>
      {{ coach.player.fullname }}
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <TextInput
            :model-value="coach.player.name"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Nombre"
            disabled
          />
          <TextInput
            :model-value="coach.player.lastname"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Apellidos"
            disabled
          />
          <TextInput
            :model-value="coach.player.phone"
            class="pb-8 pr-6 w-full lg:w-1/2"
            label="Teléfono"
            disabled
          />
          <ToggleInput
            v-model="form.is_admin"
            class="w-full flex pb-8 justify-start"
            label="Administrador"
          />
          <TextareaInput
            v-model="form.observations"
            :error="form.errors.observations"
            class="pb-8 pr-6 w-full"
            label="Observaciones"
          />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="remove">
            Eliminar
          </button>
          <LoadingButton :loading="form.processing" class="btn-indigo ml-auto" type="submit">
            Actualizar
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>
